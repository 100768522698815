/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

body,
html {
    overflow-x: hidden;
}

.container-heading-text p {
    font-family: 'Noto Sans';
}

.section-heading {
    font-size: 70px;
    font-weight: 800;
    position: absolute;
    top: 9%;
    left: 10%;
    z-index: 2;
    line-height: 1.3;
}

/*************   WELCOME SECTION    *****************/

#welcome-section {
    background-image: url('../assets/img/welcome/map-lines.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    background-color: #e2e2e2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

.welcome-img {
    width: 50%;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.welcome-text-wrapper {
    padding-top: 15vh;
    font-size: 20px;
}

.welcome-text-wrapper .container-text-right-col {
    padding-right: 20%;
}

.welcome-video-container {
    width: 60%;
    padding-top: 20vh;
}

.welcome-credits-container {
    width: 100%;
    text-align: center;
    font-weight: 600;
    padding: 20vh 0;
    -webkit-transform: rotate(-8deg);
    -ms-transform: rotate(-8deg);
    transform: rotate(-8deg);
}

.credits-heading {
    border: 2px solid #000;
    border-radius: 50%;
    padding: 6px 30px;
    margin-bottom: 2em;
    opacity: 0;
    -webkit-transform: translate(0, 10vh);
    -ms-transform: translate(0, 10vh);
    transform: translate(0, 10vh);
    -webkit-transition: all 2s;
    -o-transition: all 2s;
    transition: all 2s;
}

.fade-in {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

.credits-text-first {
    margin-top: 2em;
}

.credits-text {
    visibility: hidden;
    margin-bottom: 0.6em;
    -webkit-transition: 2s;
    -o-transition: 2s;
    transition: 2s;
}

.credits-text:hover {
    color: #29ff42;
    text-decoration: underline;
    transition: 0.2s;
}

/*************    MAKING MAVES SECTION  ****************/

#making-waves-section {
    padding: 15vh 0 10vh 0;
}

.making-waves-container {
    width: 60%;
    position: relative;
}

#christina-wong-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.making-waves-img {
    z-index: -1;
    width: 60%;
}

.waves-arrow-col {
    padding-right: 2em;
}

.making-waves-link:hover {
    color: #29ff42;
    padding-bottom: 10px;
    border-bottom: 2px solid #29ff42;
}

/* .vimeo-wrapper {
    position: relative;
    overflow: hidden;
    height: 60vh;
}

iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
}

.iframe-play-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    transform: translate(0, 50%);
    height: 50%;
    z-index: 1;
    cursor: pointer;
} */

/* #sculpture-placement-iframe {
     height: 600px;
 }

 #potr-iframe {
     height: 610px;
 } */

#sculpture-1 {
    position: absolute;
    left: -30%;
    top: 8%;
    max-width: 220px;
}

#sculpture-2 {
    position: absolute;
    left: -30%;
    top: -20%;
    max-width: 560px;
}

#sculpture-3 {
    position: absolute;
    right: -22%;
    bottom: 12%;
    max-width: 240px;
}

#sculpture-4 {
    position: absolute;
    right: -40%;
    bottom: 10%;
    max-width: 580px;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}

#glasgow-tool-1 {
    position: absolute;
    left: -48%;
    top: 10%;
    max-width: 290px;
}

#glasgow-tool-2 {
    position: absolute;
    left: -36%;
    top: 10%;
    max-width: 340px;
}

#karen-1 {
    position: absolute;
    left: -42%;
    top: 12%;
    max-width: 290px;
}

#karen-2 {
    position: absolute;
    right: -25%;
    bottom: 8%;
    max-width: 330px;
}

#potr-1 {
    position: absolute;
    left: -34%;
    bottom: 36%;
    max-width: 260px;
}

#potr-2 {
    position: absolute;
    left: -50%;
    bottom: 36%;
    max-width: 560px;
}

#potr-2 {
    position: absolute;
    left: -48%;
    bottom: 26%;
    max-width: 560px;
}

#potr-3 {
    position: absolute;
    right: -20%;
    bottom: 0;
    max-width: 170px;
}

#james-pfaff-1 {
    position: absolute;
    left: -40%;
    bottom: 50%;
    max-width: 330px;
}

#james-pfaff-2 {
    position: absolute;
    right: -22%;
    bottom: 12%;
    max-width: 190px;
}

#james-pfaff-3 {
    position: absolute;
    right: -50%;
    bottom: 10%;
    max-width: 580px;
}

#allot-me-1 {
    position: absolute;
    right: -28%;
    bottom: 12%;
    max-width: 286px;
}

#zero-waste-1 {
    position: absolute;
    left: -33%;
    bottom: 35%;
    max-width: 420px;
}

#zero-waste-2 {
    position: absolute;
    right: -20%;
    bottom: 0;
    max-width: 350px;
}

#circular-arts-1 {
    -webkit-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    transform: rotate(-200deg);
    position: absolute;
    left: -45%;
    bottom: 12%;
    max-width: 350px;
}

.making-waves-heading {
    font-size: 5em;
    font-family: Bluu-Next-Bold;
    font-weight: 600;
}

.vimeo-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    min-height: 800px;
}

.vimeo-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.making-waves-text-wrapper {
    width: 75%;
}

.making-waves-text-wrapper h5 {
    line-height: 1.6;
}

.making-waves-text-wrapper p {
    line-height: 2;
}

.waves-url-col {
    padding-left: 3rem;
}

#christina-wong-container .making-waves-text-wrapper {
    margin-left: 3em;
    width: 75%;
}

/*********    SECTIONS GENERAL SETTINGS  *********/

.figure {
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.figure-img {
    height: auto;
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.figure-figcaption {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.plus-icon {
    margin-right: 1em;
    cursor: pointer;
}

.figcaption-text {
    visibility: hidden;
}

.show-figcaption {
    visibility: visible;
    word-break: break-word;
}

.stretch-figcaption {
    visibility: visible;
}

.container-heading-text p {
    font-family: 'Noto Sans';
}

.container-text-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4em;
}

.figure-text-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4em;
    padding: 5vh 0;
}

.container-text-left-col {
    padding-left: 32%;
    width: 95%;
}

.container-text-right-col {
    padding-right: 32%;
    width: 95%;
}

.thick-line-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
}

.long-thick-line {
    width: 80%;
}

/**********      CULTURE AS THE FOURTH PILLAR OF SUSTAINABLE DEVELOPMENT    *************/

.sustainable-section {
    height: auto;
    padding-bottom: 20vh;
}

.sustainable-container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sustainable-container-1 {
    height: auto;
    background-image: url('../assets/img/sustainable/sustainable-line-1.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.sustainable-container-1 .row {
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-template-rows: 1fr;
}

.sustainable-1-left-col {
    padding-top: 80%;
}

.sustainable-heading {
    font-size: 60px;
    font-weight: 800;
    position: absolute;
    top: 9%;
    left: 10%;
    z-index: 2;
    line-height: 1.3;
}

.section-line {
    width: 100vw;
    -o-object-fit: contain;
    object-fit: contain;
}

.sustainable-line-2 {
    position: absolute;
    bottom: -78%;
    width: 100%;
}

.sustainable-heading-text {
    max-width: 70%;
    margin-left: 6%;
    margin-bottom: 5%;
    font-weight: 800;
    font-size: 25px;
}

.sustainable-figure-2 {
    margin-left: 25%;
}

.figure-wrapper-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 4em;
}

.sustainable-figure-3,
.sustainable-figure-4 {
    padding: 3em 1em;
}

.figure-wrapper-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4em;
}

.figure-wrapper-3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.sustainable-figure-6 {
    padding-top: 3em;
}

.sustainable-figure-7 {
    padding: 0 8em 4em 0;
}

.sustainable-figure-8 {
    max-width: 70%;
    float: right;
}

.sustainable-figure-9 {
    max-width: 45%;
    margin-top: -6em;
    margin-left: 7em;
}

.sustainable-figure-11 {
    max-width: 55%;
    margin-left: 35%;
}

.sustainable-figure-12 {
    max-width: 70%;
    margin-top: -5em;
}

.sustainable-figure-13 {
    margin-top: 2em;
}

.sustainable-figure-14 {
    width: 80%;
}

.sustainable-figure-15 {
    padding-left: 20%;
    padding-top: 5%;
}

.sustainable-figure-16 {
    width: 70%;
    margin-top: 2em;
}

.figure-wrapper-4 {
    background-image: url('../assets/img/sustainable/sustainable-line-2.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.sustainable-figure-17 {
    width: 50%;
    margin-left: 10%;
    margin-top: 3%;
}

/*************    AFTER NOVEMBER SECTION   *****************/

.after-november-section {
    height: auto;
    padding-bottom: 10vh;
    position: relative;
}

.after-november-container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.after-november-container-1 {
    height: auto;
    background-image: url('../assets/img/after-november/after-november-line-1.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.after-november-container-1 .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding-bottom: 3em;
}

.after-november-figure-2 {
    padding: 70% 22% 0 22%;
}

.after-november-heading {
    font-size: 70px;
    font-weight: 800;
    z-index: 2;
    line-height: 1.3;
    position: static;
}

.after-november-heading-text {
    max-width: 100%;
    font-weight: 800;
    font-size: 25px;
    padding-left: 10%;
}

.november-line-wrapper {
    position: relative;
    min-height: 8%;
    z-index: -1;
    margin-left: -8em;
}

.after-november-line-2 {
    position: absolute;
    right: -3%;
    top: 14%;
    bottom: -8%;
    max-width: 15%;
}

.after-november-line-3 {
    position: absolute;
    right: -3%;
    bottom: -8%;
    width: 100vw;
}

.after-november-orange-text {
    margin: 1.5em 0;
    padding: 1.5em 0;
    border-top: 2px solid #ff7a00;
    border-bottom: 2px solid #ff7a00;
    color: #ff7a00;
    font-size: 18px;
}

.after-november-figure-3 {
    padding: 6% 0 5% 16%;
}

.after-november-figure-4 {
    max-width: 70%;
    padding: 10% 0 0 3%;
}

.after-november-figure-5 {
    padding-left: 25%;
    padding-right: 10%;
    padding-top: 3%;
}

.after-november-figure-7 {
    padding-top: 5%;
}

.mail-link {
    color: #29ff42;
    padding-bottom: 2px;
    border-bottom: 2px solid #29ff42;
}

/***********   CLOSE OF PLAY SECTION   **************/

.close-brackets {
    position: fixed;
    left: 5%;
    bottom: 10%;
}

.close-brackets-img {
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
}

.close-of-play-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.close-of-main-row-container {
    height: 100%;
}

.close-of-main-row {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 12vh 0;
}

#art-of-the-possible {
    padding: 0 0 12vh 0;
}

.close-of-header-anim {
    position: relative;
    height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 90%;
}

.close-of-main-wrapper {
    max-width: 70%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 6fr 3em 8fr;
    grid-template-columns: 6fr 8fr;
    grid-gap: 3em;
    position: relative;
}

.close-of-col {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: auto auto 80px;
    grid-template-rows: auto auto 80px;
}

.close-of-col .row:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.close-of-col .row:last-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.close-of-second-col {
    padding: 0;
    margin: 0;
}

.close-of-second-col .row:nth-child(1) {
    border-top: 2px solid #000;
    padding: 1em 0;
}

.close-of-second-col .row:nth-child(2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.close-of-text-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
}

.close-of-text {
    font-size: 16px;
    line-height: 2;
}

.close-of-text-sustainable {
    /* padding-top: 8em; */
}

.close-of-img {
    border-bottom: 2px solid #000;
    height: 100%;
    width: auto;
    -o-object-fit: contain;
    object-fit: contain;
}

.booking-arrow-black {
    padding-top: 1.5em;
}

.data-heading {
    font-size: 32px;
}

.data-icon {
    margin-right: 1em;
}

.data-text {
    font-weight: 500;
    font-size: 16px;
}

#silver-narratives-row {
    background-color: #e5e5e5;
}

#in-process-row {
    background-color: #000;
    color: #fff;
}

#in-process-row .close-of-second-col .row:first-child {
    border-top: 2px solid #fff;
}

#in-process-row .close-of-img {
    border-bottom: 2px solid #fff;
}

#carbon-usage {
    background-color: #29ff42;
}

#sustaining {
    background-color: #dfbd95;
}

#sustaining .close-of-col {
    -ms-grid-rows: auto 500px 80px;
    grid-template-rows: auto 500px 80px;
}

#sustaining .close-of-img {
    -o-object-position: 0;
    object-position: 0;
}

/**************      CLIMATE lITERACY SECTION   ********************/

.climate-literacy-section {
    height: auto;
    padding-bottom: 20vh;
    background-color: #e2e2e2;
}

.climate-literacy-container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.climate-literacy-container-1 {
    height: auto;
    background-image: url('../assets/img/climate-literacy/climate-literacy-line-1.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.climate-literacy-container-1 .row {
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-template-rows: 1fr;
}

.climate-literacy-1-left-col {
    padding-top: 80%;
}

.climate-literacy-heading {
    font-size: 60px;
    font-weight: 800;
    position: absolute;
    top: 9%;
    left: 10%;
    z-index: 2;
    line-height: 1.3;
}

.climate-literacy-heading-text {
    padding-top: 6%;
    max-width: 70%;
    margin-left: 6%;
    margin-bottom: 5%;
    font-weight: 800;
    font-size: 25px;
}

.climate-literacy-figure-2 {
    margin-left: 25%;
}

.climate-literacy-figure-3 {
    padding: 3% 0 0 16%;
}

.climate-literacy-figure-4 {
    padding: 3% 24% 0 16%;
}

.climate-literacy-figure-5 {
    padding: 5% 15% 0 0;
}

.climate-literacy-figure-6 {
    padding: 5% 42% 0 0;
    margin-left: -18%;
}

.climate-literacy-figure-7 {
    padding: 5% 10% 0 35%;
}

.climate-literacy-figure-8 {
    padding-top: 3%;
}

.climate-literacy-figure-9 {
    padding-top: 5%;
}

.climate-literacy-figure-10 {
    padding-top: 8%;
    padding-right: 5%;
}

.climate-literacy-figure-11 {
    padding: 0 30% 0 0;
    margin-top: -6%;
}

.figure-text-wrapper-8 {
    background-image: url('../assets/img/climate-literacy/climate-literacy-line-2.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0% 70%;
}

.climate-literacy-line-2 {
    position: absolute;
    top: -26%;
    z-index: -1;
}
